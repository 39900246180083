/* eslint-disable no-console */
import { nanoid } from 'nanoid';
import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { BsFillEraserFill } from 'react-icons/bs';
import { IoAddCircleSharp } from 'react-icons/io5';
import { toast } from 'react-toastify';
import {
  BuscaProduto,
  InputMoney,
  InputNumber,
  InputPercent,
  InputPeso,
  InputText,
} from '~/components/NovosInputs';
import { LojaContext } from '~/context/loja';
import { moneyFormat } from '~/utils/functions';
import { useFocusTabProduto } from '../../hooks/useFocusTabProduto';
import { usePdvOnline } from '../../hooks/usePdvOnline';
import { produtoService } from '../../services/produto';
import { ProdutoProps, ProdutoTableProps } from '../../types';
import { PedidoProps } from '../../types/context';
import { ProdutoGrid } from './components/ProdutoGrid';
import {
  Button,
  ButtonContainer,
  GridContainer,
  ProdutoContainer,
  ProdutoContent,
  SubtotalContainer,
  SubtotalTableContainer,
  TableContainer,
  TableContent,
  ValuesContainer,
  ValuesContent,
} from './style';
import { calcSubTotalProduto } from './utils/calcSubTotalProduto';
import { subTotalTable } from './utils/calcSubTotalTable';
import { formataQtdEtiqueta } from './utils/formataQtdEtiqueta';
import { processaEtiquetaBalanca } from './utils/processaEtiquetaBalanca';
import { resetAbaProdutos } from './utils/resetFields';
import { useMediaQuery } from '~/hooks/useMediaQuery';
import { padString } from '../../utils/padString';
import { ButtonOpenCadProduto } from './components/ButtonOpenCadProduto';

export const TabProdutos: React.FC = () => {
  const {
    initialStatePdv,
    handleAddProdutoTable,
    produtosTable,
    produtoContentRef,
    isOpenModalFunction,
    handleSubTotal,
    isModalOpen,
    handlePedido,
    formTabProduto,
    isIpv,
    selectProduto,
    handleIsIpv: setIsIpv,
    handleSelectProduto: setSelectProduto,
  } = usePdvOnline();

  const { loja: currentLoja } = useContext(LojaContext);

  const { handleInputFocus } = useFocusTabProduto();

  const [percentage, setPercentage] = useState(0);
  const [isEtiqueta, setIsEtiqueta] = useState(false);
  const [isButtonLocked, setIsButtonLocked] = useState(false);

  const buttonAddRef = useRef<HTMLButtonElement | null>(null);

  const lessThan1600 = useMediaQuery({ maxWidth: 1600 });
  const lessThan1400 = useMediaQuery({ maxWidth: 1400 });

  const delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const {
    registerTabProduto,
    controlTabProduto,
    setValueTabProduto,
    getValuesTabProduto,
    resetTabProduto,
    watchTabProduto,
    resetFieldTabProduto,
    formStateTabProduto: { errors },
  } = formTabProduto;

  const watchLeitor = watchTabProduto('leitor');
  const qtdProduto = watchTabProduto('qtd_produto');
  const valDesconto = watchTabProduto('discount');
  const valUnitario = watchTabProduto('val_unit');

  const subTotalPorItem = moneyFormat(
    calcSubTotalProduto(
      qtdProduto,
      valUnitario,
      percentage,
      isEtiqueta,
      selectProduto?.flg_ipv,
    ).toString(),
  );

  const disableButtonAdd =
    !watchLeitor || valUnitario === '0,00' || !valUnitario;

  useEffect(() => {
    let percent = 0;

    if (valDesconto) {
      const parsedValue = parseFloat(valDesconto.replace(',', '.'));

      if (!Number.isNaN(parsedValue)) {
        if (parsedValue < 1) {
          percent = parsedValue * 100;
        } else if (parsedValue <= 100) {
          percent = parsedValue;
        } else {
          percent = 100;
        }
      }
    }

    setPercentage(percent);
  }, [qtdProduto, valDesconto, valUnitario]);

  const handleSelectProduto = useCallback(
    async (produto: ProdutoProps[]) => {
      if (produto.length === 0) return;

      await delay(150);

      const selectedProduto =
        produto.length > 1
          ? produto.filter((p) => p.val_preco !== null)[0]
          : produto[0];

      const gtin = selectedProduto.cod_gtin_principal;
      const valUnit = selectedProduto.val_preco ?? 0;

      const qtd = selectedProduto.flg_ipv ? '1,000' : 1;

      setValueTabProduto('qtd_produto', qtd);
      setValueTabProduto('leitor', gtin);
      setValueTabProduto('val_unit', moneyFormat(valUnit.toString()));

      handleInputFocus('qtd_produto');
      setSelectProduto(selectedProduto);
    },
    [handleInputFocus, setSelectProduto, setValueTabProduto],
  );

  const handleClearFields = useCallback(async () => {
    const fieldValues: Array<{ name: string; placeholder: string }> = [
      { name: 'leitor', placeholder: 'Código, GTIN ou Etiqueta' },
      { name: 'busca_por', placeholder: 'Código, GTIN ou Descrição' },
      { name: 'qtd_produto', placeholder: '0' },
      { name: 'discount', placeholder: '0,00' },
      { name: 'val_unit', placeholder: '0,00' },
    ];

    await delay(70);

    fieldValues.forEach(({ name, placeholder }) => {
      const input = produtoContentRef.current?.querySelector<HTMLInputElement>(
        `[name="${name}"]`,
      );
      if (input) {
        input.value = '';
        input.placeholder = placeholder;
      }
    });

    await delay(55);

    resetTabProduto(resetAbaProdutos);
    handleInputFocus('leitor', true);

    setIsIpv(false);
    setSelectProduto(null);
    setIsEtiqueta(false);

    await handleSelectProduto([]);
    setValueTabProduto('busca_por', { value: '', label: '' });
  }, [
    handleInputFocus,
    handleSelectProduto,
    produtoContentRef,
    resetTabProduto,
    setIsIpv,
    setSelectProduto,
    setValueTabProduto,
  ]);

  useEffect(() => {
    const handleKeyPress = async (event: KeyboardEvent) => {
      if (event.key !== 'Escape') return;
      await handleClearFields();
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, []);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (produtoContentRef.current && event.key === 'Tab') {
        const { activeElement } = document;
        const inputQtd = produtoContentRef.current.querySelector(
          '[name="qtd_produto"]',
        );
        const inputUnit =
          produtoContentRef.current.querySelector('[name="val_unit"]');

        const inputDiscount =
          produtoContentRef.current.querySelector('[name="discount"]');

        const focusNextInput = (nextInputName: string) => {
          event.preventDefault();
          handleInputFocus(nextInputName);
        };

        if (inputQtd === activeElement) focusNextInput('val_unit');
        else if (inputUnit === activeElement) focusNextInput('discount');
        else if (inputDiscount === activeElement) {
          event.preventDefault();
          if (disableButtonAdd) handleInputFocus('leitor');
          if (buttonAddRef.current) buttonAddRef.current.focus();
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [disableButtonAdd, handleInputFocus, produtoContentRef]);

  const handleAddProduto = useCallback(async () => {
    if (!selectProduto) return;

    const id = nanoid();

    const data: ProdutoTableProps = {
      id,
      cod_produto: selectProduto.cod_produto,
      des_produto: selectProduto.des_produto,
      des_unidade_venda: selectProduto.des_unidade_venda,
      qtd_produto: getValuesTabProduto('qtd_produto'),
      val_preco: getValuesTabProduto('val_unit').toString(),
      val_desconto: `${moneyFormat(percentage.toString())}%`,
      val_total: moneyFormat(
        calcSubTotalProduto(
          qtdProduto,
          valUnitario,
          percentage,
          isEtiqueta,
          selectProduto.flg_ipv,
        ).toString(),
      ),
    };

    const pedido: PedidoProps = {
      id,
      cod_gtin: selectProduto.cod_gtin_principal,
      cod_produto: selectProduto.cod_produto,
      val_preco: getValuesTabProduto('val_unit'),
      val_desconto: percentage,
      qtd_pedido: getValuesTabProduto('qtd_produto'),
    };

    handleAddProdutoTable(data);
    handlePedido(pedido);
    setIsEtiqueta(false);
    await delay(100);
    await handleClearFields();
  }, [
    getValuesTabProduto,
    handleAddProdutoTable,
    handleClearFields,
    handlePedido,
    isEtiqueta,
    percentage,
    qtdProduto,
    selectProduto,
    valUnitario,
  ]);

  useEffect(() => {
    handleSubTotal(subTotalTable(produtosTable));
  }, [handleSubTotal, produtosTable]);

  useEffect(() => {
    const handleKeyPress = async (event: KeyboardEvent) => {
      const produtoContent = produtoContentRef.current;
      if (!produtoContent) return;

      const inputSelectors = [
        '[name="qtd_produto"]',
        '[name="discount"]',
        '[name="val_unit"]',
      ];
      const inputs = inputSelectors.flatMap((selector) => {
        const input = produtoContent.querySelector<HTMLInputElement>(selector);
        return input ? [input] : [];
      });

      const { activeElement } = document;

      // Verifica se algum dos inputs tem foco e se a tecla Enter foi pressionada
      if (inputs.some((input) => input === activeElement)) {
        if (!disableButtonAdd && event.key === 'Enter' && !isButtonLocked) {
          setIsButtonLocked(true); // Bloqueia múltiplos cliques

          if (buttonAddRef.current) buttonAddRef.current.click();

          // Desbloqueia após 2 segundos
          setTimeout(() => {
            setIsButtonLocked(false);
          }, 2000);
        }
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => document.removeEventListener('keydown', handleKeyPress);
  }, [disableButtonAdd, handleAddProduto, produtoContentRef, isButtonLocked]);

  const buscaProduto = useCallback(async () => {
    if (!initialStatePdv || !watchLeitor) return;

    const watchLeitorString = padString(String(watchLeitor), 13, '0', 'left');
    setIsEtiqueta(false);

    if (
      !watchLeitorString.startsWith('2') &&
      watchLeitorString.length >= 8 &&
      watchLeitorString.length <= 13
    ) {
      await delay(5);

      const produto = await produtoService.getProdutoGtin(
        Number(initialStatePdv.cod_loja),
        watchLeitorString,
      );

      if (typeof produto === 'boolean') {
        toast.warning('Produto não encontrado');
        return;
      }

      setSelectProduto(produto);
      setIsIpv(false);

      const data = {
        label: `${produto.cod_produto} - ${produto.des_produto}`,
        value: produto.cod_produto,
      };
      const qtd = produto.flg_ipv ? '1,000' : 1;

      setValueTabProduto(
        'leitor',
        produto.cod_gtin_principal || produto.cod_produto,
      );
      setValueTabProduto('busca_por', data);
      setValueTabProduto('qtd_produto', qtd);
      setValueTabProduto(
        'val_unit',
        produto.val_preco ? moneyFormat(produto.val_preco.toString()) : '0,00',
      );
      handleInputFocus('qtd_produto');
    }
  }, [
    handleInputFocus,
    initialStatePdv,
    setIsIpv,
    setSelectProduto,
    setValueTabProduto,
    watchLeitor,
  ]);

  const buscaProdutoEtiqueta = useCallback(async () => {
    if (!initialStatePdv || !watchLeitor) return;

    const watchLeitorString = padString(String(watchLeitor), 13, '0', 'left');

    if (watchLeitorString.length === 13 && watchLeitorString.startsWith('2')) {
      // 2009100006569
      // 2002400009900
      const result = await processaEtiquetaBalanca(watchLeitorString, {
        qtd_dig_cod_bal: currentLoja.qtd_dig_cod_bal,
        flg_sem_dig_verif_bal: currentLoja.flg_sem_dig_verif_bal,
        tipo_totalizador_etq_bal: currentLoja.tipo_totalizador_etq_bal,
        cod_loja: Number(initialStatePdv.cod_loja),
      });

      setIsEtiqueta(true);

      if (typeof result === 'boolean') {
        resetFieldTabProduto('qtd_produto');
        setValueTabProduto('val_unit', '0,00');
        resetFieldTabProduto('busca_por');

        setSelectProduto(null);
        setIsIpv(false);

        toast.warning('Etiqueta não encontrada');
      } else {
        const gtin = result.edtCodPlu;
        const qtd = formataQtdEtiqueta(result.qtdCalculate);

        const produto = await produtoService.getProdutoGtin(
          Number(initialStatePdv.cod_loja),
          gtin,
        );

        if (typeof produto === 'boolean')
          return toast.warning('Gtin não encontrada');

        const data = {
          label: `${produto.cod_produto} - ${produto.des_produto}`,
          value: produto.cod_produto,
        };

        setSelectProduto(produto);
        setIsIpv(true);

        resetFieldTabProduto('leitor');
        handleInputFocus('discount');

        setValueTabProduto('leitor', gtin);
        setValueTabProduto('qtd_produto', qtd);
        setValueTabProduto('val_unit', moneyFormat(result.valVenda.toString()));
        setValueTabProduto('busca_por', data);
      }
    }
  }, [
    currentLoja.flg_sem_dig_verif_bal,
    currentLoja.qtd_dig_cod_bal,
    currentLoja.tipo_totalizador_etq_bal,
    handleInputFocus,
    initialStatePdv,
    resetFieldTabProduto,
    setIsIpv,
    setSelectProduto,
    setValueTabProduto,
    watchLeitor,
  ]);

  useEffect(() => {
    const handleKeyPress = async (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !isButtonLocked) {
        const produtoContent = produtoContentRef.current;
        if (!produtoContent) return;

        const inputSelectors = [
          '[name="qtd_produto"]',
          '[name="discount"]',
          '[name="val_unit"]',
        ];
        const inputs = inputSelectors.flatMap((selector) => {
          const input =
            produtoContent.querySelector<HTMLInputElement>(selector);
          return input ? [input] : [];
        });

        if (inputs.some((input) => input === document.activeElement)) return;

        await buscaProduto();
        await buscaProdutoEtiqueta();
      }
    };
    document.addEventListener('keypress', handleKeyPress);
    return () => document.removeEventListener('keypress', handleKeyPress);
  }, [buscaProduto, buscaProdutoEtiqueta, isButtonLocked, produtoContentRef]);

  return (
    <GridContainer>
      <ProdutoContainer>
        <ProdutoContent ref={produtoContentRef}>
          <InputText
            label="Leitor"
            maxLength={13}
            max={13}
            placeholder="Código, GTIN ou Etiqueta"
            name="leitor"
            register={registerTabProduto}
            control={controlTabProduto}
            isError={!!errors.leitor}
            className="inputLeitor"
            autoComplete="off"
            tabIndex={0}
            autoFocus
            disabled={
              isOpenModalFunction ||
              (isModalOpen.isOpen && isModalOpen.modal !== 'aberturaPdv')
            }
            onInput={(ev: ChangeEvent<HTMLInputElement>) => {
              const filteredValue = ev.target.value.replace(/[^0-9]/g, '');
              setValueTabProduto('leitor', filteredValue);
            }}
          />

          <BuscaProduto
            label="Pesquisa"
            placeholder="Código, GTIN ou Descrição"
            name="busca_por"
            register={registerTabProduto}
            isError={!!errors.busca_por}
            control={controlTabProduto}
            disabled={isOpenModalFunction || isModalOpen.isOpen || isIpv}
            buscaNasLojas={
              initialStatePdv
                ? Number(initialStatePdv.cod_loja)
                : currentLoja.cod_loja
            }
            customOptions={{ hideBuscarPor: true, buscarPor: ['Produto'] }}
            getProduct={async (selected: any) => {
              const product = selected as ProdutoProps[];

              const data = {
                label: `${product[0].cod_produto} - ${product[0].des_produto}`,
                value: product[0].cod_produto,
              };

              await handleSelectProduto(product);
              setValueTabProduto('busca_por', data);
            }}
            setValue={setValueTabProduto}
            style={{
              backgroundColor: 'white',
              height: '3.125rem',
              fontSize: '1.25rem',
              color: 'black',
            }}
            listWidth={lessThan1400 ? '39.375rem' : undefined}
          />
          <ValuesContainer>
            <ValuesContent>
              <div id="inputPdv">
                <label htmlFor="qtd_produto">Quantidade:</label>
                {(selectProduto?.flg_ipv || isIpv) && (
                  <InputPeso
                    id="qtd_produto"
                    name="qtd_produto"
                    decimals={3}
                    maxLength={999}
                    max={999}
                    register={registerTabProduto}
                    control={controlTabProduto}
                    isError={!!errors.qtd_produto}
                    disabled={
                      isOpenModalFunction || isModalOpen.isOpen || isIpv
                    }
                    onInput={(ev: any) => {
                      setValueTabProduto('qtd_produto', ev.target.value);
                    }}
                  />
                )}

                {!selectProduto?.flg_ipv && !isIpv && (
                  <InputNumber
                    id="qtd_produto"
                    maxLength={8}
                    max={999}
                    placeholder="0"
                    name="qtd_produto"
                    register={registerTabProduto}
                    control={controlTabProduto}
                    isError={!!errors.qtd_produto}
                    autoComplete="off"
                    allowNegativeValue={false}
                    allowsComma={false}
                    disableWheel
                    disabled={
                      isOpenModalFunction || isModalOpen.isOpen || isIpv
                    }
                    onInput={(ev: any) => {
                      setValueTabProduto('qtd_produto', ev.target.value);
                    }}
                    // style={{ width: '9.375rem' }}
                  />
                )}
              </div>
              <div id="inputPdv">
                <label
                  style={{ marginBottom: lessThan1600 ? '-5px' : '' }}
                  htmlFor="discount"
                >
                  Desconto:
                </label>
                <InputPercent
                  id="discount"
                  placeholder="0,00"
                  min={0}
                  max={100}
                  maxLength={5}
                  decimals={2}
                  name="discount"
                  register={registerTabProduto}
                  control={controlTabProduto}
                  isDisabled={isOpenModalFunction || isModalOpen.isOpen}
                  autoComplete="off"
                  isError={!!errors.discount}
                  onInput={(ev: ChangeEvent<HTMLInputElement>) => {
                    const { value } = ev.target;

                    const valueReplacement = value
                      .replace(',', '.')
                      .slice(0, 5);

                    if (Number.isNaN(parseFloat(value))) {
                      setValueTabProduto('discount', '0,00');
                    } else {
                      setValueTabProduto('discount', valueReplacement);
                    }
                  }}
                />
              </div>
            </ValuesContent>
            <ValuesContent>
              <div id="inputPdv">
                <label htmlFor="val_unit">Valor Un:</label>
                <InputMoney
                  id="val_unit"
                  placeholder="0,00"
                  min={0}
                  decimals={2}
                  maxLength={9999}
                  max={9999}
                  name="val_unit"
                  register={registerTabProduto}
                  disabled={isOpenModalFunction || isModalOpen.isOpen || isIpv}
                  control={controlTabProduto}
                  isError={!!errors.val_unit}
                  showIcon
                  onInput={(ev: ChangeEvent<HTMLInputElement>) => {
                    const { value } = ev.target;
                    setValueTabProduto('val_unit', value);
                  }}
                  style={{ width: '10.625rem' }}
                />
              </div>
              <SubtotalContainer>
                <p>SubTotal R$ {subTotalPorItem}</p>
              </SubtotalContainer>
            </ValuesContent>
          </ValuesContainer>
          <ButtonContainer>
            <ButtonOpenCadProduto />
            <Button
              disabled={
                !watchLeitor || isOpenModalFunction || isModalOpen.isOpen
              }
              onClick={async () => {
                await handleClearFields();
              }}
              className="clearButton"
              type="button"
            >
              <BsFillEraserFill size={23} />
              Limpar (Esc)
            </Button>
            <Button
              disabled={
                disableButtonAdd ||
                isOpenModalFunction ||
                isModalOpen.isOpen ||
                !selectProduto ||
                subTotalPorItem === '0,00'
              }
              onClick={async () => {
                await handleAddProduto();
              }}
              className="addButton"
              type="button"
              ref={buttonAddRef}
            >
              <IoAddCircleSharp size={23} />
              Adicionar (Enter)
            </Button>
          </ButtonContainer>
        </ProdutoContent>
      </ProdutoContainer>
      <TableContainer>
        <TableContent>
          <ProdutoGrid />
          <SubtotalTableContainer>
            <p>
              Subtotal: R${' '}
              {moneyFormat(subTotalTable(produtosTable).toString())}
            </p>
          </SubtotalTableContainer>
        </TableContent>
      </TableContainer>
    </GridContainer>
  );
};
