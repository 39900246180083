import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
`;

export const TabHeader = styled.div`
  display: flex;
  border-bottom: 2px solid #ddd;
  background: #f8f8f8;
  width: 30%;
`;

export const TabButton = styled.button<{ isActive: boolean }>`
  flex: 1;
  padding: 3px 7px;
  border: none;
  border: ${({ isActive }) =>
    isActive ? '1px solid #6200ee' : '1px solid transparent'};
  background: transparent;
  font-size: 14px;
  color: ${({ isActive }) => (isActive ? '#6200ee' : '#555')};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
`;

export const TabContent = styled.div<{ bgColor?: string }>`
  background: ${({ bgColor }) => bgColor};
  padding: 15px;
`;
