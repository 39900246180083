import styled from 'styled-components';

export const FinalizacaoContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const FinalizacaoContent = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: -10px;
`;

export const ClienteContent = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
`;

export const CpfCnpjContent = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ButtonAddCliente = styled.button`
  border: none;
  background-color: #9800ff;
  display: flex;
  align-items: center;

  padding: 0.375rem;
  height: 2.375rem;
  margin-top: 1.6938rem;

  border-top-right-radius: 0.4375rem;
  border-bottom-right-radius: 0.4375rem;

  &:disabled {
    opacity: 50%;
  }
`;

export const BuscaParceiroContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  position: relative;
`;
