import { FinalizadorasTableProps } from '~/pages/PdvOnline/types';

function removerDuplicadosPorChave<T>(array: T[], key: keyof T): T[] {
  const map = new Map<any, T>();

  array.forEach((item) => {
    map.set(item[key], item);
  });

  return Array.from(map.values());
}

export const calcRecebido = (
  finalizadoras: FinalizadorasTableProps[],
): number => {
  const somaTotal = removerDuplicadosPorChave(finalizadoras, 'id').reduce(
    (total, finalizadora) => {
      const valorParcelaString = finalizadora.vlr_parcela;
      let valorFloat: number;

      if (valorParcelaString.length >= 8)
        valorFloat = parseFloat(
          valorParcelaString.replace(/[^\d,]/g, '').replace(',', '.'),
        );
      else valorFloat = parseFloat(valorParcelaString.replace(',', '.')) || 0;

      if (!Number.isNaN(valorFloat)) return total + valorFloat;
      return total;
    },
    0,
  );

  return somaTotal;
};
