import styled from 'styled-components';

interface TableProps {
  statusPdv?: 'ABERTO' | 'FECHADO';
}

const getStatusColor = (statusPdv?: 'ABERTO' | 'FECHADO') => {
  if (statusPdv === 'ABERTO') return '#2CA639';
  if (statusPdv === 'FECHADO') return 'red';
  return 'black';
};

export const TableContainer = styled.div`
  margin-top: 0rem;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  background-color: transparent;
`;

export const TableHeaderCell = styled.th`
  padding: 0.3125rem;
  text-align: left;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f9f9f9;
  }
`;

export const TableCell = styled.td<TableProps>`
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #f2f2f2;
  user-select: none;

  font-weight: 600;
  color: ${(props) => getStatusColor(props.statusPdv)};
`;
