import React, { useCallback, useEffect } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import styled from 'styled-components';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: transparent;
  border: none;
  color: #ff7f27;
  font-size: 0.8438rem;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
  transition: background 0.2s;

  &:hover {
    background: rgba(255, 127, 39, 0.1);
  }
`;

const IconWrapper = styled.span`
  @media (max-width: 1160px) {
    display: none;
  }
`;

export const ButtonOpenCadProduto: React.FC = () => {
  const { isOpenModalFunction } = usePdvOnline();

  const baseUrl = typeof window !== 'undefined' ? window.location.origin : '';
  const fullUrl = `${baseUrl}/produto`;

  const handleClick = useCallback(() => {
    window.open(fullUrl, '_blank', 'noopener,noreferrer');
  }, [fullUrl]);

  useEffect(() => {
    const handleKeyFinalizaVenda = async (ev: KeyboardEvent) => {
      if (ev.key === 'F2') {
        ev.preventDefault();
        if (!isOpenModalFunction) handleClick();
      }
    };

    window.addEventListener('keydown', handleKeyFinalizaVenda);
    return () => window.removeEventListener('keydown', handleKeyFinalizaVenda);
  }, [handleClick, isOpenModalFunction]);

  return (
    <Button onClick={handleClick}>
      <IconWrapper>
        <FiExternalLink size={18} />
      </IconWrapper>
      CADASTRO DE PRODUTOS (F2)
    </Button>
  );
};
